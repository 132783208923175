@import '../../../styles/_globals.sass'
.Title
  &--isSeparator
    border-bottom: 1px solid rgba(0, 0, 0, 0.08)
    padding-bottom: 8px

// h1.Title,h2.Title, h3.Title, h4.Title, h5.Title
//   font-weight: bold;
//   font-family: $font-heading


h1.Title
  font-size: 36px
  margin-bottom: 28px

h2.Title
  font-size: 30px
  margin-bottom: 16px

h3.Title
  font-size: 24px
  margin-bottom: 12px

h4.Title
  font-size: 20px
  margin-bottom: 10px

h5.Title
  font-size: 16px
  margin-bottom: 8px