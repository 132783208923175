@import '_globals.sass'

a, .ant-menu-item-selected a, .ant-menu-item:hover, .ant-menu-item a:hover
  color: $accent-color

.ant-btn-primary, .ant-checkbox-checked .ant-checkbox-inner
  background: $accent-color
  border-color: $accent-color
  &:hover
    background: $accent-darken-color
    border-color: $accent-darken-color

.ant-menu-inline .ant-menu-item-selected 
  background-color: rgba($accent-color, .05) !important

.ant-menu-inline .ant-menu-item::after
  // border-right: 3px solid  $accent-color;
  border-right: 0

.ant-menu-inline .ant-menu-item-selected a::before
  background-color: $accent-color
  width: 3px
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected
  color: $accent-color

.ant-statistic-content
  font-family: $font-default

.ant-page-header
  padding-top: 0
.site-collapse-custom-collapse > .ant-collapse-item > .ant-collapse-header, .ant-collapse-content > .ant-collapse-content-box
  padding: 0px


.multi-line-label .ant-form-item-label
  padding: 4px 0 !important

  label 
    white-space: normal
    word-wrap: none
    margin-right: 8px
    display: inline-block
    text-align: right
    line-height: 16px
    position: relative
    // width: 50px;

    &:after 
      margin-right: 0