$bg-color: #151523

.icons
  &__telegram
    z-index: 999
    position: fixed
    inset: auto 2rem 2rem auto
    transition: all ease 0.3s

    &:hover
      scale: 1.35

.AppLayout
  &__container
    padding: 0px
    margin: 0
    overflow: inherit
    overflow-y: hidden
    @media (min-width: 992px)
      margin-left: 250px
      overflow-x: hidden
  &__container-full
    padding: 0px
    margin: 0
    overflow: inherit
    overflow-y: hidden
    @media (min-width: 992px)
      margin-left: 80px
  &__content
    background: #fff
    width: 100%
    min-height: 280px
    padding: 16px
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px 0px
    border-radius: 8px
    margin-bottom: 100px
    @media (min-width: 960px)
      padding: 28px
      margin: 16px 16px 100px
  &__header
    background: white
    padding: 0 16px
    border-bottom: 1px solid rgba(0, 0, 0, 0.15)
    position: relative
    &-menu
      position: absolute
      top: 0
      right: 0
      font-size: 18px
      line-height: 0
      padding: 22px 16px
  &__sider
    background-color: #fff
    position: relative
    overflow: auto
    &-logo__container
      margin-top: 24px
      margin-bottom: 20px
      display: flex
      flex-direction: column
      margin-left: 23px
    &-lang
      margin-top: 40px
      right: 23px
      font-size: 13px
      // color: rgba(255, 255, 255, 0.45);
      z-index: 200
