.ImageCropper
  margin-bottom: 32px
  &__description
    opacity: 0.6
    margin-top: 0
  &__input
    margin-bottom: 12px
  &__save
    margin-right: 12px
  &__buttons
    margin-top: 12px