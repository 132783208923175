.BatchImageCropper
  margin-bottom: 32px
  &__description
    opacity: 0.6
    margin-top: 0
  &__input
    margin-bottom: 12px
  &__save
    margin-right: 12px
  &__buttons
    margin-top: 12px

  &__images
    display: flex
    flex-flow: row wrap
    margin-left: -12px
    margin-right: -12px
    margin-bottom: -12px
  &__image
    margin: 0 12px 12px 12px
  &__image-name
    overflow-wrap: break-word
    margin-bottom: 3px
    font-size: 11px
