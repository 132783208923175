@import './ant.sass'

@font-face
  font-family: 'BreezeSans'
  font-weight: normal
  src: url(../assets/fonts/BreezeSans-Regular_20150728.woff2) format("woff2"), url(../assets/fonts/BreezeSans-Regular_20150728.woff) format("woff")

// @font-face
//   font-family: 'BreezeSans'
//   font-weight: bold
//   src: url(../assets/fonts/BreezeSans-Bold_20150728.woff2) format("woff2"), url(../assets/fonts/BreezeSans-Bold_20150728.woff) format("woff")

// @font-face
//   font-family: 'Space Grotesk'
//   font-weight: bold
//   src: url(../assets/fonts/space-grotesk/spacegrotesk-bold-webfont.woff2) format("woff2"), url(../assets/fonts/space-grotesk/spacegrotesk-bold-webfont.woff) format("woff")

@font-face
  font-family: 'BreezeSans'
  font-weight: 600
  src: url(../assets/fonts/BreezeSans-Medium_20150728.woff2) format("woff2"), url(../assets/fonts/BreezeSans-Medium_20150728.woff) format("woff")

body
  margin: 0
  padding: 0
  font-family: $font-default, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji', sans-serif
  background: rgba(0, 0, 0, 0.05)
  font-weight: 500

html
  box-sizing: border-box
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

*
  box-sizing: inherit

  &:before, &:after
    box-sizing: inherit

h1, h2, h3, h4, h5
  font-weight: 600
  // font-family: 'Wide', sans-serif
  font-family: $font-heading

h1
  font-size: 48px

h2
  font-size: 36px

h3, h4
  font-size: 24px

h5
  font-size: 16px

#root
  height: 100%
  width: 100%
  overflow: auto
  height: 100vh

.code
  display: block
  overflow: auto
  text-align: left
  white-space: pre
  word-spacing: normal
  word-wrap: normal
  word-break: normal
  hyphens: none
  margin: 12px 0 20px
  padding: 22px
  font-size: 14px
  background: rgba(150, 150, 150, 0.1)
  border-radius: 3px

.active-color
  color: $accent-color

.ant-descriptions-item-label
  color: rgba(0,0,0,.45)

.ant-checkbox-disabled .ant-checkbox-inner
  background-color: #f5f5f5!important
  border-color: #d9d9d9!important

.ag-theme-alpine
  font-family: BreezeSans, monospace
  font-weight: normal

.ag-theme-alpine .ag-header-cell
  font-weight: bold
  font-size: 14px

.ag-theme-alpine .ag-cell-value
  font-weight: normal
  font-size: 14px

.ant-menu-item-group-title
  height: unset!important
