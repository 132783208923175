.PageListHeader
  padding-right: 0
  padding-left: 0

.CampaignsListPage .ant-page-header-heading
  justify-content: flex-start

.CampaignsListPage .ant-page-header-heading-extra
  width: 100%

.CampaignsListPage .ant-page-header-heading-extra .ant-space.ant-space-horizontal.ant-space-align-center
  justify-content: space-between
  width: 100%
  font-weight: bold

.CampaignsListPage .ant-alert.ant-alert-error.ant-btn-lg
  height: 60px
  font-size: 20px
